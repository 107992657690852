//
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Import Bootstrap functions to use inside variables values
@import "bootstrap/scss/functions";

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f3f6ff;


// Grayscale
$white:                       #fff;
$gray-100:                    #f8f8f8;
$gray-200:                    #fcf5ef;
$gray-300:                    #f1e9e2;
$gray-400:                    #e5ddd4;
$gray-500:                    #b4b7c9;
$gray-600:                    #9397ad;
$gray-700:                    #565973;
$gray-800:                    #33354d;
$gray-900:                    #0b0f19;
$black:                       #000;

// Theme colors
$primary:                     #fd8c04;
$secondary:                   #fdc93d;
$danger:                      #b20008;
$warning:                     #fdc93d;

//Accordions
$accordion-icon-box-bg:                     tint-color($warning, 90%);

//Pagination

$pagination-hover-bg:                       $gray-100;
$pagination-active-color:                   $white;
$pagination-active-bg:                      $primary;
