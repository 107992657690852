//
// user.scss
// Use this to write your custom SCSS
//
//custom-line-heights

.lh-1-1 {
  line-height: 1.1;
}

.lh-1-2 {
  line-height: 1.2;
}
